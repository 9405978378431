import { FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { EXPIRY_DATE_FORMAT } from "constants/transaction";
import { SummaryDetailWrapper } from "common/details/summary";

type Props = {
  transaction: {
    notaryMeetingTime: string | null;
    notaryMeetingTimezone: string | null;
  };
};

function NotaryMeetingTime({ transaction }: Props) {
  const { notaryMeetingTime, notaryMeetingTimezone } = transaction;
  if (!notaryMeetingTime) {
    return null;
  }

  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage
          id="3016ad63-75f7-4ab0-a8e2-a28ca5f2703b"
          defaultMessage="Notary meeting time"
        />
      }
      definition={
        <CustomFormattedDateTime
          value={notaryMeetingTime}
          asTimeZone={notaryMeetingTimezone}
          formatStyle={EXPIRY_DATE_FORMAT}
        />
      }
    />
  );
}

export default NotaryMeetingTime;
