import { type ReactNode, type ReactElement } from "react";
import { useIntl, FormattedMessage, type IntlShape } from "react-intl";

import {
  Feature,
  OrganizationTransactionCreationSource,
  OrganizationTransactionDetailedStatus,
  UserAction,
  OrganizationTypeEnum,
  MortgageTransactionType,
  CompletionRequirement,
  OrganizationTransactionStatus,
  OrganizationTransactionVariant,
  RequiredFeature,
} from "graphql_globals";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { formattedTransactionType } from "common/mortgage/transactions/utils";
import TransactionMessageSubject from "common/signer/items/transaction_message_subject";
import TransactionMessage from "common/signer/items/transaction_message";
import TransactionSignature from "common/signer/items/transaction_signature";
import AdminTransactionDetailsDateTimes from "common/details/summary/datetimes";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";
import PaymentStatus from "common/transactions/payment_status";
import PayerType from "common/transactions/payer_type";
import TransactionMarkFree from "common/transactions/mark_free";
import TransactionHacks from "common/hacks/transaction";
import ActivationLink from "common/transactions/activation_link";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import SignerFailedKbaAlert, {
  useSignerFailedKba,
} from "common/transactions/signer_failed_kba_alert";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";
import { SECTIONS } from "constants/details/summary";
import APP from "constants/applications";
import Env from "config/environment";
import { isNotaryDocumentDownloadProhibited } from "common/notary/capacity";
import { useMutation } from "util/graphql";
import { userFullName } from "util/user";
import { ESIGN_ONLY_TRANSACTIONS } from "constants/transaction";
import { DescriptionListItem } from "common/core/description_list";

import SigningTimeSchedule from "./signing_time_schedule";
import AdminTools from "./admin_tools";
import type { DocumentBundleForTransactionDetailsSummary } from "./index_fragment.graphql";
import AdminUpdateOrganizationTransactionMutation from "./admin_update_organization_transaction_mutation.graphql";
import TransactionOrganizationDisplay from "./transaction_organization_display";
import { DeprecatedSummary } from "./deprecated/deprecated_index";
import { PropertyInfoContainer } from "./property_information";
import { ReferralInfoContainer } from "./referral_info";

type Props = {
  bundle: DocumentBundleForTransactionDetailsSummary;
  entry?: string;
  viewer: {
    user: null | {
      id: string;
      notaryProfile?: Parameters<typeof isNotaryDocumentDownloadProhibited>[0];
      organization?: null | {
        organizationType: OrganizationTypeEnum;
        canShowPaymentInTransactionForInvoicing: boolean;
      };
    };
  };
  refetch: () => Promise<unknown>;
};

const { hasHacksEnabled } = Env;
const CREATION_SOURCE_LABELS: Readonly<{
  [k in OrganizationTransactionCreationSource]?: ReactElement;
}> = Object.freeze({
  [OrganizationTransactionCreationSource.NOTARIZE]: (
    <FormattedMessage
      id="5e432784-ce07-42ee-a96e-9a820165e0ec"
      defaultMessage="Created from Notarize"
    />
  ),
  [OrganizationTransactionCreationSource.NOTARIZE_API]: (
    <FormattedMessage
      id="3d3276f6-faf1-45f3-9aa5-5e9d714a167d"
      defaultMessage="Created from Notarize (API)"
    />
  ),
  [OrganizationTransactionCreationSource.EASYLINK]: (
    <FormattedMessage
      id="937d81ca-3ecd-44da-b425-0445d3fd5d90"
      defaultMessage="Created from Notarize (EasyLink)"
    />
  ),
  [OrganizationTransactionCreationSource.ENCOMPASS]: (
    <FormattedMessage
      id="5eea2c27-9e13-4e46-bba2-4c08d9a93af7"
      defaultMessage="Created from Encompass"
    />
  ),
  [OrganizationTransactionCreationSource.DROPBOX]: (
    <FormattedMessage
      id="5d5a0a62-b28e-40b5-84ba-353e774982fe"
      defaultMessage="Created from Dropbox"
    />
  ),
  [OrganizationTransactionCreationSource.RESWARE]: (
    <FormattedMessage
      id="55ca1c7f-0ff2-4bcd-901b-6a9d2fcddd4b"
      defaultMessage="Created from Resware"
    />
  ),
});

export function renderTransactionTypeWithVariant(
  transactionType: string | null,
  transactionVariant: OrganizationTransactionVariant,
  isMortgage: boolean,
  requiresNsaMeeting: boolean,
  intl: IntlShape,
  ps1583AttestationEnabled: boolean,
) {
  const requiresMeeting = isMortgage || ESIGN_ONLY_TRANSACTIONS.includes(transactionType ?? "");
  return requiresMeeting ? (
    formattedTransactionType({ requiresNsaMeeting, type: transactionType, intl })
  ) : transactionType ? (
    <>
      {formattedTransactionVariant(transactionVariant, intl)} ({transactionType})
    </>
  ) : (
    formattedTransactionVariant(transactionVariant, intl, ps1583AttestationEnabled)
  );
}

export function SummaryDetailWrapper({
  term,
  definition,
}: {
  term: ReactNode;
  definition: ReactNode;
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={term} definition={definition} />
  ) : (
    <DeprecatedDetailGridRow title={term}>{definition}</DeprecatedDetailGridRow>
  );
}

/** should only be used for Admin & Biz portals */
function Summary(props: Props) {
  const { bundle, entry: portal, viewer, refetch } = props;
  const viewerOrganization = viewer.user?.organization;
  const notaryProfile = viewer.user?.notaryProfile;
  const { retrievalId, retrievalPin, transaction, completionRequirements, referralInfo, meetings } =
    bundle;
  const execRefetch = () => refetch();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const [kbaData] = useSignerFailedKba({
    documentBundleId: bundle.id,
    transactionStatus: transaction.detailedStatus,
    kbaRequired: Boolean(
      completionRequirements?.includes(CompletionRequirement.NOTARIZATION) ||
        bundle.participants?.some((participant) => participant?.proofRequirement?.kba),
    ),
  });
  const adminUpdateOrganizationTransactionMutateFn = useMutation(
    AdminUpdateOrganizationTransactionMutation,
  );

  const updateOrganizationTransaction = async (params: {
    activationTime: string | undefined | null;
    activationTimezone: string | undefined | null;
    expiry: string | undefined | null;
    expiryTimezone: string | undefined | null;
  }) => {
    await adminUpdateOrganizationTransactionMutateFn({
      variables: {
        input: {
          id: transaction.id,
          activationTime: params.activationTime,
          activationTimezone: params.activationTimezone,
          expiry: params.expiry,
          expiryTimezone: params.expiryTimezone,
        },
      },
    });
    return execRefetch();
  };

  function detailSection(key: keyof typeof SECTIONS, content: ReactNode) {
    return <DescriptionListItem term={intl.formatMessage(SECTIONS[key])} definition={content} />;
  }

  function ihnNotaryAssignmentSection(
    transaction: NonNullable<DocumentBundleForTransactionDetailsSummary["transaction"]>,
  ) {
    const isNotarization =
      transaction.transactionVariant === OrganizationTransactionVariant.NOTARIZATION;

    if (!isNotarization || (!transaction.closer && !transaction.notarizeCloserOverride)) {
      return null;
    }
    return detailSection(
      "notaryAssignment",
      // Hard-assigned to a specific notary
      transaction.closer ? (
        <FormattedMessage
          id="809864e1-b300-4ad1-9166-04b45aaf3535"
          defaultMessage="IHN - {notaryName}"
          values={{ notaryName: userFullName(transaction.closer) }}
        />
      ) : (
        // Explicitly assigned to the Notarize network
        <FormattedMessage
          id="5e5a09ab-f8a1-4e7e-87ce-46a97c06c0f9"
          defaultMessage="Notarize network"
        />
      ),
    );
  }

  const isWetSignTransaction = transaction.transactionType === MortgageTransactionType.wet_sign;

  const showAdvancedDetails = Boolean(
    hasPermissionFor("advancedTransactionDetails") ||
      transaction.publicOrganization.featureList.includes(Feature.ADVANCED_TRANSACTION_CREATION),
  );
  const showCustomizedEmail =
    Boolean(transaction.publicOrganization.featureList.includes(Feature.CUSTOM_EMAILS)) &&
    isWetSignTransaction;

  const isMortgageTransaction = transaction.isMortgage;

  const showReadOnlyBlurb =
    !hasPermissionFor("seeNotarizationSummaryReadOnly") &&
    isMortgageTransaction &&
    transaction.userAction !== UserAction.EDIT &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.DRAFT;

  const showSignerFailedKbaAlert = kbaData.length > 0;

  const isReschedulerEnabled =
    (!transaction.lenderInitiated || transaction.isCollaborative) &&
    !showReadOnlyBlurb &&
    // at the moment, lenders do NOT want this functionality. they would rather recall and/or recreate the transaction
    // because the docs have to change anyway
    viewerOrganization?.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
    transaction.status !== OrganizationTransactionStatus.SUCCESS;

  const meetingIdDisplay = () => {
    return (
      <div>
        {hasPermissionFor("meetingDetails") && (
          <span>
            <FormattedMessage
              id="1e902959-3e9c-4ad5-ab60-a1624e63acf2"
              defaultMessage="{meetingCount, plural, one{# meeting has} other{# meetings have}} occurred"
              values={{ meetingCount: meetings.edges.length }}
            />{" "}
          </span>
        )}
        {meetings.edges.map((edge, index) => {
          const meetingId = edge.node.id;
          return (
            <span key={meetingId}>
              {index ? <span>, </span> : ""}
              {hasPermissionFor("meetingDetails") ? (
                <Link underlined={false} to={`/analytics/page/1/${meetingId}/notary`}>
                  {meetingId}
                </Link>
              ) : (
                <span>{meetingId}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const requiredFeature = (feature: RequiredFeature) =>
    bundle.requiredFeatures?.includes(feature) ? (
      <FormattedMessage id="011ccd7a-92e6-4c78-aed8-6650bfece12c" defaultMessage="Yes" />
    ) : (
      <FormattedMessage id="ba2f68a5-2c69-435c-9d8d-78a9a7fe697f" defaultMessage="No" />
    );

  const showTitleAgencyInfo =
    (portal === APP.LENDER && transaction.titleAgency) ||
    (hasPermissionFor("summaryTitleAgencySection") && transaction.titleAgency);
  const showLenderInfo =
    (portal === APP.TITLE_AGENCY && transaction.lenderName) ||
    (hasPermissionFor("summaryLenderSection") && transaction.lenderName);

  const showPropertyInfo =
    isMortgageTransaction &&
    (transaction.address?.line1 ||
      showTitleAgencyInfo ||
      showLenderInfo ||
      transaction.fileNumber ||
      transaction.loanNumber ||
      transaction.titleUnderwriter ||
      transaction.recordingLocation ||
      transaction.jurisdictionEligibilityConsent);

  return (
    <>
      {showSignerFailedKbaAlert && (
        <SignerFailedKbaAlert
          kbaFailureList={kbaData}
          timezone={transaction.expiryTimezone || null}
        />
      )}
      {/* Customer portals "Schedule" section */}
      {!hasPermissionFor("summaryTransactionScheduleInfo") && (
        <SigningTimeSchedule
          transactionId={transaction.id}
          isReschedulerEnabled={isReschedulerEnabled}
        />
      )}
      {/* Keystone "Schedule" section */}
      {hasPermissionFor("transactionDetailsDateTimes") && (
        <AdminTransactionDetailsDateTimes
          transaction={transaction}
          onSubmitActivationForm={(values) => {
            return updateOrganizationTransaction({
              activationTime: values?.activation,
              activationTimezone: values?.activationTimezone,
              expiry: transaction.expiry,
              expiryTimezone: transaction.expiryTimezone,
            });
          }}
          onSubmitExpiryForm={(values) => {
            return updateOrganizationTransaction({
              activationTime: transaction.activationTime,
              activationTimezone: transaction.activationTimezone,
              expiry: values?.expiry,
              expiryTimezone: values?.expiryTimezone,
            });
          }}
        />
      )}
      {showPropertyInfo && (
        <PropertyInfoContainer
          portal={portal}
          transaction={transaction}
          execRefetch={execRefetch}
        />
      )}
      {detailSection("meetings", meetingIdDisplay())}
      {CURRENT_PORTAL === APP.ADMIN && (
        <>
          {detailSection("credibleWitness", requiredFeature(RequiredFeature.CREDIBLE_WITNESS))}
          {detailSection("transactionWitness", requiredFeature(RequiredFeature.WITNESS))}
          <TransactionOrganizationDisplay transactionOrg={transaction.publicOrganization} />
        </>
      )}
      {retrievalId && detailSection("accessId", retrievalId)}
      {retrievalPin &&
        hasPermissionFor("downloadTransactionDocuments") &&
        !isNotaryDocumentDownloadProhibited(notaryProfile, transaction) &&
        detailSection("accessPin", retrievalPin)}
      {referralInfo && <ReferralInfoContainer referralInfo={referralInfo} />}
      {hasPermissionFor("summaryCreationSource") &&
        detailSection(
          "creationSource",
          CREATION_SOURCE_LABELS[transaction.creationSource] || transaction.creationSource,
        )}
      {ihnNotaryAssignmentSection(transaction)}
      {showAdvancedDetails && detailSection("externalId", transaction.externalId)}
      {showCustomizedEmail && (
        <>
          <TransactionMessageSubject transaction={transaction} />
          <TransactionMessage transaction={transaction} />
          <TransactionSignature transaction={transaction} />
        </>
      )}
      <PaymentStatus
        bundle={bundle}
        canShowPaymentInTransactionForInvoicing={Boolean(
          viewerOrganization?.canShowPaymentInTransactionForInvoicing,
        )}
        renderPriceBreakdown={(charge) => (
          <PriceBreakdownWithRefund refetch={execRefetch} charge={charge} />
        )}
        adminCapabilities={hasPermissionFor("paymentStatus")}
        onUpdate={execRefetch}
      />
      {hasPermissionFor("summaryPayerInfo") && (
        <>
          {!bundle.charges?.length && (
            <DeprecatedDetailGridSection>
              <DeprecatedDetailGridRow
                title={
                  <FormattedMessage
                    id="adedff8d-3a16-437e-8e37-e3dbdbaa4a81"
                    defaultMessage="Payer"
                  />
                }
              >
                <PayerType
                  // PayerType only handles updating transaction.payer
                  // if there are charges then the PayerType field will be rendered in the charge details
                  canUpdate={Boolean(transaction)}
                  bundle={bundle}
                  payerType={bundle.payer}
                  onUpdate={execRefetch}
                />
              </DeprecatedDetailGridRow>
            </DeprecatedDetailGridSection>
          )}
          {hasPermissionFor("markBundleFree") && (
            <TransactionMarkFree onUpdate={execRefetch} bundle={bundle} />
          )}
          <ActivationLink activationLink={transaction.activationLink} />
          <AdminTools organizationTransactionId={transaction.id} documentBundleId={bundle.id} />
        </>
      )}
      {hasHacksEnabled && <TransactionHacks documentBundleId={bundle.id} />}
    </>
  );
}

export function SummaryContainer({ bundle, entry, viewer, refetch }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <Summary bundle={bundle} entry={entry} viewer={viewer} refetch={refetch} />
  ) : (
    <DeprecatedSummary bundle={bundle} entry={entry} viewer={viewer} refetch={refetch} />
  );
}
