import { useState } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";

import { SECTIONS } from "constants/details/summary";
import Link from "common/core/link";
import Button from "common/core/button";
import { TextInput } from "common/core/form/text";
import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import Styles from "./index.module.scss";
import UpdateOrganizationTransactionMutation, {
  type UpdateOrganizationTransaction,
  type UpdateOrganizationTransactionVariables,
} from "./update_organization_transaction_mutation.graphql";
import { SummaryDetailWrapper } from "..";

const MESSAGES = defineMessages({
  loanNumberInput: {
    id: "92a03072-8e2e-4b38-8e75-9dfd3abc013b",
    defaultMessage: "Loan number input",
  },
  error: {
    id: "2ff69fc9-3614-4d64-a933-4461aeedd80a",
    defaultMessage: "Loan number could not be updated, please contact support.",
  },
});

export default function LoanNumberDetails({
  transactionId,
  loanNumber,
  canEdit,
}: {
  transactionId: string;
  loanNumber: string | null;
  canEdit: boolean;
}) {
  const intl = useIntl();
  const [showEditForm, updateShowEditForm] = useState(false);
  const [tempLoanNumber, setTempLoanNumber] = useState(loanNumber || "");
  const [loading, setLoading] = useState(false);
  const updateOrganizationTransaction = useMutation<
    UpdateOrganizationTransaction,
    UpdateOrganizationTransactionVariables
  >(UpdateOrganizationTransactionMutation);

  const updateLoanNumber = async () => {
    setLoading(true);
    try {
      const { data } = await updateOrganizationTransaction({
        variables: {
          input: {
            id: transactionId,
            transaction: {
              loanNumber: tempLoanNumber,
            },
          },
        },
      });
      setTempLoanNumber(
        data?.updateOrganizationTransactionV2?.organizationTransaction.loanNumber || "",
      );
      updateShowEditForm(false);
      setLoading(false);
    } catch (e) {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(MESSAGES.error),
      });
      captureException(e);
    }
  };

  return (
    <SummaryDetailWrapper
      term={intl.formatMessage(SECTIONS.loanNumber)}
      definition={
        showEditForm ? (
          <div className={Styles.editContent}>
            <FormattedMessage
              id="96d59d09-e6df-49e4-b8e4-874f86283c2d"
              defaultMessage="Loan number"
            />
            <TextInput
              aria-invalid={false}
              aria-label={intl.formatMessage(MESSAGES.loanNumberInput)}
              value={tempLoanNumber || ""}
              data-automation-id="loan-number-input"
              onChange={(evt) => setTempLoanNumber(evt.target.value)}
            />
            <div className={Styles.editButtonList}>
              <Button
                buttonColor="action"
                variant="secondary"
                automationId="cancel-loan-number"
                onClick={() => {
                  updateShowEditForm(false);
                  setTempLoanNumber(loanNumber || "");
                }}
              >
                <FormattedMessage
                  id="afe52181-3a35-407c-b833-96863f7be174"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                buttonColor="action"
                variant="primary"
                automationId="save-loan-number"
                onClick={updateLoanNumber}
                isLoading={loading}
              >
                <FormattedMessage
                  id="928957c0-060f-4a7c-8b9d-ac4ab8c4e65f"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </div>
        ) : (
          <div className={Styles.content}>
            {loanNumber && <div className={Styles.loanNumber}>{loanNumber}</div>}
            {canEdit && (
              <Link
                onClick={() => {
                  updateShowEditForm(true);
                }}
                automationId="edit-loan-number"
              >
                <FormattedMessage
                  id="7eb27ad3-fd25-4dcf-bbd7-96c62e3eca29"
                  defaultMessage="Edit loan number"
                />
              </Link>
            )}
          </div>
        )
      }
    />
  );
}
